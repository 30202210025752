import { Component } from '@angular/core';
import { JoyrideService } from "ngx-joyride";

@Component({
  selector: 'app-root',
  templateUrl: 'app.component.html',
  styleUrls: ['app.component.scss'],
})
export class AppComponent {
  public appPages = [
    { title: 'Temas', url: '/folder', icon: 'albums' },
    { title: 'Practicas', url: '/practicar', icon: 'library' },
    { title: 'Oraciones', url: '/oraciones', icon: 'git-network' },
    { title: 'Datos Curiosos', url: '/datos-curiosos', icon: 'information-circle' },
    { title: 'Enseñanzas', url: '/ensenanzas', icon: 'school' },
    { title: 'Donaciones', url: '/donaciones', icon: 'heart-circle' },
    { title: 'Contacto', url: '/a', icon: 'chatbubble-ellipses' },
    { title: 'Mi Cuenta', url: '/mi-cuenta', icon: 'person-circle' },
    { title: 'Mi Cuenta', url: '/login', icon: 'person-circle' },
  ];
  constructor(private joyrideService: JoyrideService,) {
    

    // this.joyrideService.startTour(
    //   {
    //     steps: ['firstStep', 'secondStep'],
    //     customTexts: { prev: 'Ant', next: 'Sig', done: 'ok' },
    //     startWith: 'firstStep'

    //   } // Your steps order
    // );
  }

  playstore(){
    window.open('https://play.google.com/store/apps/details?id=com.ontech.apostol_de_la_palabra.sc_8AFYU4', '_system', 'location=yes');
  }

}
