import { NgModule } from '@angular/core';
import { PreloadAllModules, RouterModule, Routes } from '@angular/router';
import { AngularFireAuthGuard, redirectUnauthorizedTo } from '@angular/fire/compat/auth-guard';
const redirectUnauthorizedToLogin = () => redirectUnauthorizedTo(['folder']);
const routes: Routes = [
  {
    path: '',
    redirectTo: 'folder',
    pathMatch: 'full'
  },
  {
    path: 'folder',
    loadChildren: () => import('./folder/folder.module').then( m => m.FolderPageModule)
  },
  {
    path: 'tema/:id',
    loadChildren: () => import('./pages/tema/tema.module').then( m => m.TemaPageModule)
  },
  {
    path: 'practicar',
    loadChildren: () => import('./pages/practicar/practicar.module').then( m => m.PracticarPageModule)
  },
  {
    path: 'pruebas/:id',
    loadChildren: () => import('./pages/pruebas/pruebas.module').then( m => m.PruebasPageModule)
  },
  {
    path: 'login',
    loadChildren: () => import('./pages/login/login.module').then( m => m.LoginPageModule)
  },
  {
    path: 'editor',
    loadChildren: () => import('./pages/editor/editor.module').then( m => m.EditorPageModule),
    canActivate: [AngularFireAuthGuard],
    data: { authGuardPipe: redirectUnauthorizedToLogin }
  },
  {
    path: 'crear/:id',
    loadChildren: () => import('./pages/crear/crear.module').then( m => m.CrearPageModule)
  },
  {
    path: 'crear-seccion',
    loadChildren: () => import('./pages/crear-seccion/crear-seccion.module').then( m => m.CrearSeccionPageModule)
  },
  {
    path: 'crear-practica',
    loadChildren: () => import('./pages/crear-practica/crear-practica.module').then( m => m.CrearPracticaPageModule)
  },
  {
    path: 'nuestra-mision',
    loadChildren: () => import('./pages/nuestra-mision/nuestra-mision.module').then( m => m.NuestraMisionPageModule)
  },
  {
    path: 'mi-cuenta',
    loadChildren: () => import('./pages/mi-cuenta/mi-cuenta.module').then( m => m.MiCuentaPageModule)
  },
  {
    path: 'oraciones',
    loadChildren: () => import('./pages/oraciones/oraciones.module').then( m => m.OracionesPageModule)
  },
  {
    path: 'ensenanzas',
    loadChildren: () => import('./pages/ensenanzas/ensenanzas.module').then( m => m.EnsenanzasPageModule)
  },
  
  {
    path: 'editor-multiple',
    loadChildren: () => import('./pages/editor-multiple/editor-multiple.module').then( m => m.EditorMultiplePageModule),
    canActivate: [AngularFireAuthGuard],
    data: { authGuardPipe: redirectUnauthorizedToLogin }
  },
  {
    path: 'datos-curiosos',
    loadChildren: () => import('./pages/datos-curiosos/datos-curiosos.module').then( m => m.DatosCuriososPageModule)
  },
  {
    path: 'documento/:id',
    loadChildren: () => import('./pages/documento/documento.module').then( m => m.DocumentoPageModule)
  },
  {
    path: 'donaciones',
    loadChildren: () => import('./pages/donaciones/donaciones.module').then( m => m.DonacionesPageModule)
  },
  {
    path: '**',
    redirectTo: 'folder',
  },

  

 


  

  




];

@NgModule({
  imports: [
    RouterModule.forRoot(routes, { preloadingStrategy: PreloadAllModules })
  ],
  exports: [RouterModule]
})
export class AppRoutingModule {}
